import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import { Navigation } from 'react-minimal-side-navigation'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import Highlight from 'react-highlight'
import Befores from '../../components/Befores/Befores'
import 'highlight.js/styles/panda-syntax-dark.css'

const ConnectApi = () => {
  useLayoutEffect(() => {}, [])

  const baseURL = 'https://connect.consupedia.com/api/v2/'

  return (
    <Layout>
      <title>Consupedia - Connect API Getting Started</title>
      {/* <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Webhook</h1>
          </div>
        </div>
      </div> */}
      <section className="alignfull sidebar-page">
        <div className="nav">
          <>
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId="/api-reference/connect"
              onSelect={({ itemId }) => {
                // maybe push to the route
                navigate(itemId)
              }}
              items={[
                {
                  title: 'Getting started',
                  itemId: '/getting-started'
                },
                {
                  title: 'Create account',
                  itemId: '/getting-started/#create-account'
                }
              ]}
            />
          </>
        </div>
        <div className="section-container">
          <div>
            <span className="spacer" id="connect-api"></span>
            <h2>Getting started</h2>

            <p>
              Welcome to the Consupedia API Getting Started guide! If you're
              ready to harness Consupedia's sustainability data for your
              applications, services, or data analytics, you're in the right
              place. This guide aims to give you the key information to
              understand, select, and integrate our APIs effectively.
            </p>

            <p>
              Following this guide, you'll get acquainted with the various
              integrations and services we offer, understanding which ones are
              best suited for your specific needs.
            </p>

            <p>
              Whether you're an experienced developer or just starting with API
              integrations, our goal is to make your journey with the Consupedia
              API seamless. Alongside this guide, our comprehensive API
              reference documentation, tutorials, and dedicated support team are
              ready to equip you with the tools necessary for a successful
              integration with our sustainability data.
            </p>

            <p>
              So let's start this journey together towards building more
              sustainable and informed applications!
            </p>

            <span className="spacer" id="create-account"></span>
            <h2>Create an account</h2>
            <p>
              Before you start using our services you need to create an account
              and acquire an API-key.
            </p>
            <p>
              At this time, this is done by contacting Consupedia's sale team
              at&nbsp;
              <a href="mailto: info@consupedia.com" target="_blank">
                info@consupedia.com
              </a>{' '}
              or the contact form on{' '}
              <a href="https://consupedia.com/team/" target="_blank">
                consupedia.com/team
              </a>
              .
            </p>

            <span className="spacer" id="subscribe"></span>
            <h2>Subscribe to a product</h2>
            <p>
              In order to get sustainability data about a product, you need to
              subscribe to a it. You do this by using the{' '}
              <a href="/api-reference/connect/#subscribe" target="_blank">
                subscription endpoint
              </a>{' '}
              of the Connect API. The endpoint is{' '}
              <code style={{ display: 'inline-block' }}>
                <b>POST</b> /subscribe/
              </code>{' '}
              and you should send an array of the GTIN/EANS's (14 digits) you
              want to subscribe to.
            </p>
            <p>
              Let's say we want to get sustainability data of a bottle of 'Coca
              Cola' with GTIN 05000112637922.
            </p>
            <Highlight languages={['sh']}>
              {`
  curl -X POST 'https://connect.consupedia.com/api/v2/subscribe/' \\
  -H 'X-API-KEY: your_api_key' \\
  -H 'Content-Type: application/json' \\
  -d '[
    {
        "gtin": "05000112637922",
    }
]'`}
            </Highlight>
            <p>
              Replace <i>your_api_key</i> with your own key.
            </p>
            <p>We will get back a response with the result:</p>
            <Highlight languages={['json']}>
              {`{
  "status": "success",
  "message": "Products subscribed",
  "client": "Your name",
  "productsSubscribed": 1,
  "unknownProductsSubscribed": 0,
  "productsSkipped": 0
}`}
            </Highlight>
            <span className="spacer" id="get-data"></span>
            <h2>Get sustainability data</h2>
            <p>
              Now that we have subscribed to a product, we can get data. Since
              we only need data for our Coca Cola bottle, we will use the{' '}
              <a
                href="/v2/docs/#tag/products/paths/~1api~1v2~1products~107622200000012/get"
                target="_blank"
              >
                single endpoint
              </a>{' '}
              of the Connect API. The endpoint is{' '}
              <code style={{ display: 'inline-block' }}>
                <b>GET</b> /products/:gtin
              </code>
              .
            </p>
            <Highlight languages={['sh']}>
              {`
  curl -X GET 'https://connect.consupedia.com/api/v2/product/05000112637922' \\
  -H 'X-API-KEY: your_api_key' \\
  -H 'Content-Type: application/json' \\'`}
            </Highlight>
            <p>We will get back a response with the available data:</p>
            <Highlight languages={['json']}>
              {`{
    "id": 6450,
    "gtin": "05000112637922",
    "name": "Kolsyrad Läskedryck",
    "climateScore": 80,
    "CO2e": 0.09,
    "novaScore": null,
    "nutriScore": "E",
    "healthScore": 15,
    "antibioticsResistanceScore": 97.9,
    "overconsumptionRisk": "",
    "allergenList": "",
    "socialJusticeScore": 75,
    "equalityScore": 96,
    "workerRightsScore": 94.7,
    "animalProtectionScore": 85.7,
    "childLaborScore": 100,
    "biodiversityScore": 68.8,
    "pesticidesUseScore": null,
    "fertilizersUseScore": 94,
    "landUseScore": null,
    "waterScore": 58,
    "transportScore": 95,
    "packageInformation": "",
    "isEco": null
}`}
            </Highlight>
            <span className="spacer" id="web-hook"></span>
            <h2>Receive data on update</h2>
            <p>
              Sustainability data, including Consupedia's dataset, is
              continually changing. For example, you might want to keep track of
              updates to the data for a Coca Cola bottle. One way to do this is
              to set up a regular task, or 'cron job', to query the product
              endpoint. However, a more efficient way is to use the{' '}
              <a href="/webhook">Webhook service</a>. This service sends you
              updates immediately when they occur, keeping you in the loop
              without the need for constant manual checking.
            </p>
            <p>The steps we need to take to setup a webhook:</p>
            <ol>
              <li>
                Setup a small webservice that is exposed to the World Wide Web.
                See an example in node.js below.
              </li>
              <li>
                Generate a secret token and provide it to Consupedia along with
                the address to your webservice. You must also have this secret
                token securely stored on your server.
              </li>
              <li>
                When you receive a webhook request, retrieve the
                X-Hub-Signature-256 header from the request. This is the hash
                signature that Consupedia has computed from the payload and the
                secret token.
              </li>
              <li>
                Compute your own hash of the payload using the same algorithm
                and compare it with the hash signature from the request. If they
                match, the request is genuine.
              </li>
              <li>
                Now we can update our product database with the latest
                information from Consupedia as soon as it is available.
              </li>
            </ol>
            <Highlight languages={['javascript']}>
              {`const express = require('express');
const bodyParser = require('body-parser');
const crypto = require('crypto');

const app = express();
const secret = 'your_secret_token'; // Replace with your actual secret token

app.use(bodyParser.json({
  verify: (req, res, buf) => {
    req.rawBody = buf;
  }
}));

app.post('/webhook', (req, res) => {
  // Retrieve the X-Hub-Signature-256 header
  const signature = req.get('X-Hub-Signature-256');

  // Compute our own hash of the payload
  const hash = crypto.createHmac('sha256', secret)
    .update(req.rawBody)
    .digest('hex');

  // Compare our hash with the hash signature from the request
  if (signature !== \`sha256=\${hash}\`) {
    console.log('Request signature does not match computed signature');
    return res.status(401).send('Invalid signature');
  }
  const data = req.body
  console.log('Received data:', data);
  // Now we can use our data!
  res.status(200).send('OK');
});

const port = 3000;

app.listen(port, () => {
  console.log(\`Server is running and listening on port \${port}\`);
});`}
            </Highlight>
            <span className="spacer" id="next-steps"></span>
            <h2>Next Steps</h2>
            <p>
              Get familiar with all the functionality of the Connect API{' '}
              <a href="/api-reference/connect">here</a>. If you have your own
              product data, it is possible to send the data when creating
              subscriptions.
            </p>
            <p>
              For any technical support, send an email to{' '}
              <a href="mailto:tech-support@consupedia.com" target="_blank">
                tech-support@consupedia.com
              </a>
              .
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ConnectApi
